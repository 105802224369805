import React from 'react'
import NavBar from './NavBar'
import styled from 'styled-components'
import Name from './Name';

const FirstContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #212223;`;

export default function First() {
  return (
    <FirstContainer>
      <NavBar/>
      <Name/>
    </FirstContainer>
  )
}
