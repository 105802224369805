import React from "react"
import styled from "styled-components"
import Stack from "../images/brand-stack_overflow.svg"
import Github from "../images/brand-github.svg"
import Discord from "../images/brand-discord.svg"
import { StaticImage } from "gatsby-plugin-image"
const NavContainer = styled.div`
  width: 100%;
  height: 8vh;
  align-items: center;
`
const Items = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
`
const ItemsTwo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Space = styled.div`
  margin-left: 30px;
`
export default function NavBar() {
  return (
    <NavContainer>
      <Items>
        <StaticImage style={{width:'38px', height:'38px', borderRadius:'100%'}} src="https://avatars.githubusercontent.com/u/67511608?v=4" alt="avatar2" />
        <ItemsTwo>
          <Space>
            <img src={Stack} width={38} height={38} alt="stack" />
          </Space>
          <Space>
            <a href="https://github.com/Marusiella"><img src={Github} width={38} height={38} alt="github" /></a>
          </Space>
          <Space>
            <img src={Discord} width={38} height={38} alt="messenger" />
          </Space>
        </ItemsTwo>
      </Items>
    </NavContainer>
  )
}
