import React from "react"
import { createGlobalStyle } from "styled-components"
import First from "../components/First";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300;1,400&display=swap');
  body {
    margin: 0;
  }`;


export default function Home() {
  return <div>
<GlobalStyle/>
<First/>
  </div>
}
