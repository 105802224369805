import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Seo from "./Seo"
const Text = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 6rem;
  font-weight: 600;
  color: #fff;
  position: relative;
  z-index: 99;
  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 117px;
    background-color: #4c566a;
    top: 130px;
    left: -60px;
    z-index: -1;
  }
  @media (max-width: 768px) {
    font-size: 4rem;
    &::before {
      width: 200px;
      height: 80px;
      top: 93px;
      left: -26px;
    }
  }
`
const Container = styled.div`
  width: 100%;
  height: 92vh;
  display: flex;
`
const Half1 = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* display: ${props => {
    if (props.screen > 1200) {
      return "none"
    } else {
      return "flex"
    }
  }}; */
  @media (max-width: 1200px) {
    width: 100%;
  }
`
const Half2 = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* display: ${props => {
    if (props.screen > 1200) {
      return "none"
    } else {
      return "flex"
    }
  }}; */
  @media (max-width: 1200px) {
    display: none;
  }
`

const MainBox = styled.div`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 13px 70px 13px;
  outline: 0;
  color: #f4f4f4;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  z-index: 99999;
  margin-top: 50px;
  margin-left: -205px;
  &::before {
    content: "";
    background-color: #4c566a;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 10px;
    left: -10px;
    transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover::before {
    top: -10px;
    left: 10px;
  }
  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    content: "";
    border: 2px solid #f4f4f4;
  }
  @media (max-width: 1200px) {
    margin-left: -100px;
  }
`
export default function Name() {
  return (
    <Container>
    <Seo title="Hi" />
      <Half1>
        <Text>
          Hi,
          <br />
          I'm <span style={{ color: "#ffd700" }}>Marek</span>!
        </Text>
        <MainBox>About Me</MainBox>
      </Half1>

      <Half2>
        <StaticImage
          src="../images/unknown.png"
          alt="avatar"
          style={{ width: "60%" }}
          placeholder={"blurred"}
        />
      </Half2>
    </Container>
  )
}
